.imageplaceholder span {
  display: block;
  width: 96px;
  height: 96px;
  border-radius: 50%;
}

.profileimage {
  display: flex;
  border: 0;
  width: 96px;
  height: 96px;
  margin-right: 50%;
  margin-left: 50%;
  border-radius: 50%;
  background-color: #adaeaf;
  background-repeat: no-repeat;
  background-size: 96px 96px;
}

.inside {
  /*display: none;*/
  display: flex;
  color: #fff;
  align-items: center;
  justify-items: center;
  opacity: 0;
  border-radius: 50%;
}
.inside p {
  text-align: center;
}
.inside:hover {
  opacity: 1;
  cursor: pointer;
  background-color: black;
  opacity: 0.5;
}
